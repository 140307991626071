import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`With open source `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` or Smallstep Certificate Manager you can create an entirely new private PKI setup or an intermediate CA from your existing PKI. Intermediate CAs (also called subordinate CAs) are used to sign and issue leaf certificates to subscribers. Intermediates aren't generally included in trust stores, making them easier to revoke and rotate, so certificate issuance from an intermediate typically is online and automated.`}</p>
    <p>{`This tutorial will walk you through three ways of bootstrapping `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to create new PKI or an intermediate CA.`}</p>
    <h2>{`About this tutorial`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to stand up a new private certificate authority or create an intermediate CA to your existing PKI.`}</li>
      <li parentName="ul">{`Examples include copy/paste code blocks and specific commands for Active Directory (ADCS), AWS Private CA (ACM-PCA), OpenSSL, and CFSSL.`}</li>
      <li parentName="ul">{`When complete, you will have a fully functioning certificate authority or intermediate CA that can issue X.509 or SSH certificates. `}</li>
      <li parentName="ul">{`Estimated effort: Reading time ~3 mins, Lab time ~10 to 60 mins. `}</li>
    </ul>
    <Alert severity="info" mdxType="Alert">
  <div>
    If you run into any issues please let us know <Link href="https://github.com/smallstep/certificates/discussions" mdxType="Link">in GitHub Discussions</Link>.
  </div>
    </Alert>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Open Source -`}</strong>{` This tutorial assumes you have initialized and started up a `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`instance using the steps in `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started"
        }}>{`Getting Started`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https:/prof.infra.smallstep.com/certificate-manager"
          }}>{`Smallstep Certificate Manager`}</a>{` -`}</strong>{` Please contact `}<a parentName="li" {...{
          "href": "mailto:support@smallstep.com"
        }}>{`Smallstep Customer Success`}</a>{` and we will assist in creating your intermediate authority off an existing Root.`}</li>
    </ul>
    <h2>{`Overview`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-easy-way"
        }}>{`The easy way`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-medium-way"
        }}>{`The medium way`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-secure-way"
        }}>{`The secure way`}</a></li>
    </ul>
    <h2>{`The Easy Way`}</h2>
    <p>{`If you have your root CA signing key available, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca init --root=[ROOT_CERT_FILE] --key=[ROOT_PRIVATE_KEY_FILE]
`}</code></pre>
    <p><strong parentName="p">{`Note:`}</strong>{`  The root certificate can be in PEM or DER format, and the signing
key can be a PEM file containing a PKCS#1, PKCS#8, or RFC5915 (for EC) key.`}</p>
    <h2>{`The Medium Way`}</h2>
    <p>{`If you have your own root certificate and intermediate certificate and key pair
then all you'll need to do is move them to the right locations and update your
`}<inlineCode parentName="p">{`$(step path)/config/ca.json`}</inlineCode>{` configuration file.`}</p>
    <h3>{`1. Use `}<inlineCode parentName="h3">{`step`}</inlineCode>{` to generate a boilerplate configuration`}</h3>
    <p>{`It's easiest to run `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
      }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{` to get the boilerplate configuration in place, then remove or replace these artifacts with new ones that are tied to your existing root CA.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step ca init
`}</code></pre>
    <p>{`When you run `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
      }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{` we create a couple artifacts under `}<inlineCode parentName="p">{`~/.step/`}</inlineCode>{`. The important ones for us are:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`~/.step/certs/root_ca.crt`}</inlineCode>{` the CA certificate`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`~/.step/secrets/root_ca_key`}</inlineCode>{` the CA signing key`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`~/.step/certs/intermediate_ca.crt`}</inlineCode>{` the intermediate CA cert`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`~/.step/secrets/intermediate_ca_key`}</inlineCode>{` the intermediate signing key used by step-ca`}</li>
    </ul>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` does not actually need the root CA signing key. So you can remove that file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`shred -u ~/.step/secrets/root_ca_key
`}</code></pre>
    <h3>{`2. Replace `}<inlineCode parentName="h3">{`step-ca`}</inlineCode>{`'s root CA cert and intermediate CA cert/key  with your existing PKI.`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ mv root.crt ~/.step/certs/root_ca.crt
$ mv intermediate.crt ~/.step/certs/intermediate_ca.crt
$ mv intermediate_ca_key ~/.step/secrets/intermediate_ca_key
`}</code></pre>
    <p>{`Verify that the `}<inlineCode parentName="p">{`$(step path)/config/ca.json`}</inlineCode>{` is pointing to the correct location
for each of these files.`}</p>
    <p>{`That's it! You should now be able to start `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` and generate X.509 certificates
that can be validated and authenticated by any software that trusts your root
certificate.`}</p>
    <h2>{`The Secure Way`}</h2>
    <p>{`Let's face it; you probably wouldn't be reading this if you were looking for
the easy way. It's bad practice to move private keys around. Below you will
find the more complex instructions to "bootstrap from an existing PKI" `}<em parentName="p">{`the
right way`}</em>{` by generating a CSR, signing it with your existing root, and
configuring `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to use it. `}</p>
    <h3>{`1. Use `}<inlineCode parentName="h3">{`step`}</inlineCode>{` to generate a boilerplate configuration`}</h3>
    <p>{`It's easiest to run `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
      }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{` to get the boilerplate configuration in place, then remove or replace these artifacts with new ones that are tied to your existing root CA.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step ca init
`}</code></pre>
    <p>{`When you run `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
      }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{` we create a couple artifacts under `}<inlineCode parentName="p">{`~/.step/`}</inlineCode>{`. The important ones for us are:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`~/.step/certs/root_ca.crt`}</inlineCode>{` the CA certificate`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`~/.step/secrets/root_ca_key`}</inlineCode>{` the CA signing key`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`~/.step/certs/intermediate_ca.crt`}</inlineCode>{` the intermediate CA cert`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`~/.step/secrets/intermediate_ca_key`}</inlineCode>{` the intermediate signing key used by step-ca`}</li>
    </ul>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` does not actually need the root CA signing key. So you can remove that file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`rm ~/.step/secrets/root_ca_key
`}</code></pre>
    <h3>{`2. Replace `}<inlineCode parentName="h3">{`step-ca`}</inlineCode>{`'s root CA cert with your existing root certificate and generate a new signing key and intermediate certificate.`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ mv  </path/to/your/existing/root.crt> ~/.step/certs/root_ca.crt
`}</code></pre>
    <p>{`Now you need to generate a new signing key and intermediate certificate signed by your existing root CA. To do that, we can use the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/certificate/create"
      }}><inlineCode parentName="a">{`step certificate create`}</inlineCode></a>{` subcommand to generate a certificate signing request (CSR) that we'll have your existing root CA sign, producing an intermediate certificate.`}</p>
    <p>{`To generate those artifacts run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step certificate create "Intermediate CA Name" intermediate.csr intermediate_ca_key --csr
`}</code></pre>
    <h3>{`3. Transfer the CSR file and get it signed.`}</h3>
    <p>{`Now, you will need to transfer the CSR (intermediate.csr) file to your existing root CA and get it signed. Below we have examples of
how to do this using `}<inlineCode parentName="p">{`step`}</inlineCode>{`, Active Directory Certificate Services, AWS Certificate Manager Private CA, OpenSSL, and CFSSL.`}</p>
    <h4>{`Use `}<inlineCode parentName="h4">{`step`}</inlineCode>{` to sign your intermediate CSR`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step certificate sign --profile intermediate-ca intermediate.csr root.crt root.key
`}</code></pre>
    <h4>{`Active Directory Certificate Services`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`certreq -submit -attrib "CertificateTemplate:SubCA" intermediate.csr intermediate.crt
`}</code></pre>
    <h4>{`AWS Certificate Manager Private CA`}</h4>
    <p>{`You can now use the following python script that uses issue-certificate to process the CSR:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`import boto3
import sys

AWS_CA_ARN = '[YOUR_PRIVATE_CA_ARN]'

csr = ''.join(sys.stdin.readlines())

client = boto3.client('acm-pca')
response = client.issue_certificate(
    CertificateAuthorityArn=AWS_CA_ARN,
    Csr=csr,
    SigningAlgorithm='SHA256WITHRSA',
    TemplateArn='arn:aws:acm-pca:::template/SubordinateCACertificate_PathLen1/V1',
    Validity={
        'Value': 5,
        'Type': 'YEARS'
    }
)
print(f"Creating certificate with ARN {response['CertificateArn']}...", file=sys.stderr, end='')
waiter = client.get_waiter('certificate_issued')
waiter.wait(
    CertificateAuthorityArn=AWS_CA_ARN,
    CertificateArn=response['CertificateArn']
)
print('done.', file=sys.stderr)
response = client.get_certificate(
   CertificateArn=response['CertificateArn'],
   CertificateAuthorityArn=AWS_CA_ARN
)
print(response['Certificate'])
`}</code></pre>
    <p>{`To run it, fill in the ARN of your CA and run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ python issue_certificate.py < intermediate.csr > intermediate.crt
`}</code></pre>
    <h4>{`OpenSSL`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`openssl ca -config [ROOT_CA_CONFIG_FILE] \\
  -extensions v3_intermediate_ca \\
  -days 3650 -notext -md sha512 \\
  -in intermediate.csr \\
  -out intermediate.crt
`}</code></pre>
    <h4>{`CFSSL`}</h4>
    <p>{`For CFSSL you'll need a signing profile that specifies a 10-year expiry:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ cat > ca-smallstep-config.json <<EOF
{
  "signing": {
    "profiles": {
      "smallstep": {
        "expiry": "87660h",
        "usages": ["signing"]
      }
    }
  }
}
EOF
`}</code></pre>
    <p>{`Now use that config to sign the intermediate certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ cfssl sign -ca ca.pem \\
    -ca-key ca-key.pem \\
    -config ca-smallstep-config.json \\
    -profile smallstep
    -csr intermediate.csr | cfssljson -bare
`}</code></pre>
    <p>{`This process will yield a signed intermediate.crt certificate (or cert.pem for CFSSL). Transfer this file back to the machine running step-ca.`}</p>
    <h3>{`4. Replace the intermediate.crt and signing key`}</h3>
    <p>{`Finally, replace the intermediate .crt and signing key produced by step ca init with the new ones we just created:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ mv intermediate.crt ~/.step/certs/intermediate_ca.crt
$ mv intermediate_ca_key ~/.step/secrets/intermediate_ca_key
`}</code></pre>
    <p>{`That's it! You should now be able to start `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` and generate X.509 certificates
that can be validated and authenticated by any software that trusts your root
certificate.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      